<template>
  <div class="replace">
    <h3 class="replace__title">Замена</h3>
    <div class="replace__content">

      <div class="replace__block">
        <div class="replace__block-header">
          <span>Код</span>
          <label>
            <span>Сохранить набор</span>
            <input v-model="isSaveCode" type="checkbox" @change="setSaveCode" />
          </label>
        </div>
        <textarea
          v-model="code"
          class="form-control form-control--textarea"
          rows="10"
          @input="replace"
          @change="setSaveCode"
      />
      </div>

      <div class="replace__block">
        <div class="replace__block-header">
          <span>Переменные</span>
        </div>
        <textarea
            v-model="strVariables"
            class="form-control form-control--textarea"
            rows="10"
            placeholder="Введите"
            @input="replace"
        />
      </div>

      <result-output
          :arr-extra="arrResult"
          :str-extra="strResult"
      />

    </div>
  </div>
</template>

<script>
import helpers from "@/helpers";
import ResultOutput from "@/components/ResultOutput";

export default {
  name: "Replace",
  components: {
    ResultOutput
  },
  data () {
    return {
      code: 'custom docker-container ПЕРЕМЕННАЯ uid=0 gid=0 interval=4 timeout=10 alert=<0 cat /tmp/docker_ps.txt | grep -Ec \'^ПЕРЕМЕННАЯ\'',
      isSaveCode: false,
      strVariables: '',
      arrResult: [],
      strResult: ''
    }
  },
  created () {
    const tempStr = localStorage.getItem('replace-save-code-true')

    if (tempStr) {
      this.isSaveCode = true
      this.code = tempStr
    }
  },
  methods: {
    replace () {
      const arrVariables = helpers.breakIntoWords(this.strVariables)

      this.arrResult = []

      arrVariables.forEach(variable => {
        this.arrResult.push(
            this.code.replaceAll(/ПЕРЕМЕННАЯ|переменная/g, variable)
        )
      })

      this.strResult = this.arrResult.join(`\n`)
    },
    setSaveCode () {
      if (this.isSaveCode) {
        localStorage.setItem('replace-save-code-true', this.code)
      } else {
        localStorage.removeItem('replace-save-code-true')
      }
    }
  }
}
</script>

<style lang="scss">
.replace {
  &__title {
    margin: 0 0 24px 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 500px;
    grid-gap: 12px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__block-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
}

</style>
